import React from "react";
import { Grid, Header } from "semantic-ui-react";
import styles from "./NotFound.module.css";

function NotFound() {
  return (
    <Grid container centered className={styles.grid}>
      <Header as="h1" content="404" color="pink" textAlign="center" />
      <Header
        as="h2"
        content="Oops, The Page you are looking for can't be found!"
        color="grey"
        textAlign="center"
      />
    </Grid>
  );
}

export default NotFound;
