import { oauth2googleCalendar } from "../api/oauthAPI";

export async function oauthLoader({ request }) {
    const url = new URL(request.url);
    const code = url.searchParams.get('code');
  
    if (!code) {
      throw new Response("Authorization code is missing", { status: 400 });
    }
  
    try {
    await oauth2googleCalendar(code);
    } catch(error){
      throw new Response("Failed to handle OAuth callback", { status: 400 });
    }
  
    return new Response("Success", { status: 200 });
  }