import server from "./server";
import Booking, { BookingUser } from "../models/bookingModel";
import { parse } from "date-fns";
import formatISO from "date-fns/formatISO";

async function getBookingList(
  start: string,
  end: string,
  lineId?: string
): Promise<Booking[]> {
  return await server
    .get(`/api/v1/bookings?start=${start}&end=${end}`)
    .then((response: any) => response.data);
}

async function getUserList(): Promise<BookingUser[]> {
  return await server
    .get("/api/v1/bookings/users")
    .then((response: any) => response.data);
}

async function createBooking(
  line_id,
  date,
  startTime,
  endTime,
  description,
  comment,
  realName
) {
  const requestBody = prepareRequest(
    line_id,
    date,
    startTime,
    endTime,
    description,
    comment,
    realName
  );

  await server.post("/api/v1/bookings", requestBody);
}

async function updateBooking(
  id,
  line_id,
  date,
  startTime,
  endTime,
  description,
  comment,
  realName
) {
  const requestBody = prepareRequest(
    line_id,
    date,
    startTime,
    endTime,
    description,
    comment,
    realName
  );

  await server.put(`/api/v1/bookings/${id}`, requestBody);
}

function prepareRequest(
  line_id,
  date,
  startTime,
  endTime,
  description,
  comment,
  realName
) {
  const startTimeFormat =
    startTime.length === 5 ? "yyyy-MM-dd HH:mm" : "yyyy-MM-dd HH:mm:ss";
  const parsedStartDate = parse(
    `${date} ${startTime}`,
    startTimeFormat,
    new Date()
  );
  const booking_time = formatISO(parsedStartDate);

  const endTimeFormat =
    endTime.length === 5 ? "yyyy-MM-dd HH:mm" : "yyyy-MM-dd HH:mm:ss";
  const parsedEndDate = parse(`${date} ${endTime}`, endTimeFormat, new Date());
  const end_time = formatISO(parsedEndDate);
  return {
    line_id,
    real_name: realName,
    booking_time,
    end_time,
    description,
    comment,
  };
}

async function cancelBooking(id) {
  await server.put(`/api/v1/bookings/${id}/cancel`);
}

export {
  getBookingList,
  getUserList,
  createBooking,
  updateBooking,
  cancelBooking,
};
