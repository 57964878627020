import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { usePermission } from "./PermissionProvider";
import NotFound from "./NotFound";
import Loading from "../component/Loading";

interface ProtectedRouteProps {
  isAdminRoute?: boolean;
  children?: React.ReactElement;
}
const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  isAdminRoute = false,
  children = null,
}) => {
  const { admin, isLogin, checkPermission } = usePermission();
  const [isCheckingAuth, setIsCheckingAuth] = useState(true);
  const location = useLocation();
  useEffect(() => {
    const verifyAuth = async () => {
      await checkPermission();
      setIsCheckingAuth(false);
    };
    if (!isLogin) {
      verifyAuth();
    } else {
      setIsCheckingAuth(false);
    }
  }, [isLogin, checkPermission]);
  if (isCheckingAuth) {
    return <Loading />;
  }
  if (!isLogin && location.pathname !== "/login") {
    return <Navigate to="/login" replace />;
  }

  if (isAdminRoute && !admin) {
    return <NotFound />;
  }
  return children ? children : <Outlet />;
};

export default ProtectedRoute;
