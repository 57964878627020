import { SyntheticEvent, useState } from "react";
import {
  Form,
  Button,
  Modal,
  Dropdown,
  DropdownProps,
} from "semantic-ui-react";
import { createBooking, updateBooking } from "../api/bookingAPI";
import { useFormik } from "formik";
import { isBefore, isAfter } from "date-fns";
import _ from "lodash";
import Booking from "../models/bookingModel";

export type FriendOption = {
  key: string;
  text: string;
  value: string;
  image: { avatar: boolean; src: string };
};

export type ModalForm = {
  id?: number;
  picture: string;
  lineId: string;
  description: string;
  comment: string;
  realName: string;
  date: string;
  startTime: string;
  endTime: string;
};

export type ModalContent = {
  title: string;
};

interface BookingCreateProps {
  open: boolean;
  onClose: () => void;
  onRefresh: () => void;
  onCancel: (id: number) => void;
  modalForm: ModalForm;
  friendOptions: FriendOption[];
  bookingList: Booking[];
  updateBookingList: (start) => void;
}
const isBeforeOrEqual = (date1, date2) => {
  return !isAfter(date1, date2);
};
const isAfterOrEqual = (date1, date2) => {
  return !isBefore(date1, date2);
};
const BookingCreateModal: React.FC<BookingCreateProps> = (
  props: BookingCreateProps
) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [confirmDeleteModal, setConfirmDeleteModal] = useState<boolean>(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: props.modalForm,
    validate: async ({
      id,
      lineId,
      description,
      realName,
      startTime,
      endTime,
      date,
    }) => {
      const errors: { description?; lineId?; realName?; endTime?; startTime? } =
        {};
      await props.updateBookingList(`${date}`);
      const busyTime = props.bookingList.map((data) => {
        return { id: data.id, start: data.booking_time, end: data.end_time };
      });

      if (!description) {
        errors.description = "Required";
      }
      if (!realName) {
        errors.realName = "Required";
      }
      if (!lineId) {
        errors.lineId = "Required";
      }
      busyTime
        .filter((data) => data.id !== id)
        .forEach(({ start, end }) => {
          if (
            isBeforeOrEqual(
              new Date(start),
              new Date(`${date} ${startTime}`)
            ) &&
            isAfterOrEqual(new Date(end), new Date(`${date} ${startTime}`))
          ) {
            errors.startTime = "Overlap booking time";
          }
          if (
            isBeforeOrEqual(new Date(start), new Date(`${date} ${endTime}`)) &&
            isAfterOrEqual(new Date(end), new Date(`${date} ${endTime}`))
          ) {
            errors.endTime = "Overlap booking time";
          }
        });
      const endTimeArr = endTime.split(":");
      const startTimeArr = startTime.split(":");
      if (
        endTimeArr[0] < startTimeArr[0] ||
        (endTimeArr[0] == startTimeArr[0] && endTimeArr[1] < startTimeArr[1])
      ) {
        errors.endTime = "Must after startTime";
      }
      return errors;
    },
    onSubmit: async ({
      id,
      lineId,
      date,
      startTime,
      description,
      endTime,
      comment,
      realName,
    }) => {
      setLoading(true);
      try {
        if (id) {
          await updateBooking(
            id,
            lineId,
            date,
            startTime,
            endTime,
            description,
            comment,
            realName
          );
        } else {
          await createBooking(
            lineId,
            date,
            startTime,
            endTime,
            description,
            comment,
            realName
          );
        }
        await props.onRefresh();
      } catch (e) {
        setLoading(false);
        return;
      }

      setLoading(false);
      props.onClose();
    },
  });
  const handleSubmit = () => formik.handleSubmit();
  const handleDelete = async () => {
    if (formik.values.id === undefined) {
      return;
    }
    await props.onCancel(formik.values.id);
    await props.onRefresh();
    setConfirmDeleteModal(false);
    props.onClose();
  };
  const handleSearch = (options, query) => {
    return options.filter((opt) => {
      if (_.isNil(opt.text)) {
        return false;
      }
      return opt.text.toLowerCase().includes(query.toLowerCase());
    });
  };
  const handleDropdownSelect = (
    e: SyntheticEvent<HTMLElement, Event>,
    { value }: DropdownProps
  ) => formik.setFieldValue("lineId", value);
  const handleOnChangeDate = async (e) => {
    formik.handleChange(e);
  };
  return (
    <form>
      <Modal
        onClose={props.onClose}
        open={props.open}
        closeOnDimmerClick={false}
      >
        <Modal.Header>預約設定</Modal.Header>
        <Modal.Content image>
          <Modal.Description>
            <Form>
              <Dropdown
                id="lineId"
                placeholder="Select Friend"
                fluid
                selection
                search={handleSearch}
                name="lineId"
                options={props.friendOptions}
                value={formik.values.lineId}
                onChange={handleDropdownSelect}
                error={!!formik.errors.lineId}
              />
              <Form.Input
                id="date"
                type="date"
                label="日期"
                name="date"
                value={formik.values.date}
                onChange={handleOnChangeDate}
              />
              <Form.Input
                id="startTime"
                type="time"
                label="開始時間"
                name="startTime"
                value={formik.values.startTime}
                onChange={formik.handleChange}
                error={formik.errors.startTime}
              />
              <Form.Input
                id="endTime"
                type="time"
                label="結束時間"
                name="endTime"
                value={formik.values.endTime}
                onChange={formik.handleChange}
                error={formik.errors.endTime}
              />
              <Form.Input
                id="realName"
                placeholder="王小明"
                label="姓名"
                type="text"
                name="realName"
                value={formik.values.realName}
                onChange={formik.handleChange}
                error={formik.errors.realName}
              />
              <Form.Input
                id="description"
                placeholder="微醺一下"
                label="描述"
                type="text"
                name="description"
                value={formik.values.description}
                onChange={formik.handleChange}
                error={formik.errors.description}
              />
              <Form.Input
                id="comment"
                placeholder="舊客戶"
                label="備註"
                type="text"
                name="comment"
                value={formik.values.comment}
                onChange={formik.handleChange}
                error={formik.errors.comment}
              />
            </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          {props.modalForm.id !== undefined && (
            <Button
              icon="trash"
              negative
              onClick={() => setConfirmDeleteModal(true)}
              content="取消"
            ></Button>
          )}
          <Button icon="times" onClick={props.onClose} content="關閉" />
          <Button
            icon="check"
            loading={loading}
            content="送出"
            type="button"
            disabled={Object.keys(formik.errors).length > 0 || loading}
            positive
            onClick={handleSubmit}
          />
        </Modal.Actions>
        <Modal
          onClose={() => setConfirmDeleteModal(false)}
          open={confirmDeleteModal}
          closeOnDimmerClick={false}
          size="small"
        >
          <Modal.Header>取消確認</Modal.Header>
          <Modal.Content>
            <p>確定要取消？</p>
          </Modal.Content>
          <Modal.Actions>
            <Button
              icon="times"
              content="關閉"
              onClick={() => {
                setConfirmDeleteModal(false);
              }}
            />
            <Button
              negative
              icon="check"
              content="取消"
              onClick={handleDelete}
            />
          </Modal.Actions>
        </Modal>
      </Modal>
    </form>
  );
};

export default BookingCreateModal;
