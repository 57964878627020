import { BaseSyntheticEvent, useState } from "react";
import { Button, Table, Image, Search } from "semantic-ui-react";
import { useLoaderData, useNavigate } from "react-router-dom";
import _ from "lodash";
import User from "../models/userModel";
import icon from "../assets/user.png";
import { updateBalance } from "../api/userAPI";
import BalanceUpdateModal, {
  defaultModalForm,
  ModalForm,
  ModalContent,
} from "./BalanceUpdateModal";
import styles from "./userList.module.css";

function UserList() {
  const [submitFail, setSubmitFail] = useState(false);
  const [load, setLoad] = useState(false);
  const [open, setOpen] = useState(false);
  const [modalContent, setModalContent] = useState<ModalContent>({
    title: "",
    name: "",
  });
  const [modalForm, setModalForm] = useState<ModalForm>(defaultModalForm);
  const [error, setError] = useState<ModalForm>({
    amount: undefined,
    description: undefined,
    order_date: undefined,
  });
  const [search, setSearch] = useState<string>();
  const showDescription = modalContent.title !== "儲值設定";
  const users = useLoaderData() as User[];
  const navigate = useNavigate();
  const noError = Object.keys(error).every((key) => _.isEmpty(error[key]));
  const handleClose = () => setOpen(false);
  const handleChange = (e: BaseSyntheticEvent) => {
    const key = e.target.name;
    let field;
    if (key === "amount") {
      field = "金額";
    }
    setModalForm({ ...modalForm, [key]: e.target.value });
    if (_.isEmpty(e.target.value)) {
      setError({ ...error, [key]: `${field} 是必填的` });
    } else {
      setError({ ...error, [key]: null });
    }
  };
  const handleSubmit = async () => {
    setSubmitFail(false);
    setLoad(true);
    const type = modalContent.title === "儲值設定" ? "deposit" : "consume";
    try {
      await updateBalance(modalContent.id, type, {
        ...modalForm,
        type: "ADMIN",
      });
      setOpen(false);
      navigate("/users", { replace: true });
    } catch (e) {
      setSubmitFail(true);
    }
    setLoad(false);
  };
  const handleSearch = (e, data) => {
    setSearch(data.value);
  };
  return (
    <div>
      <Search
        placeholder="Search..."
        onSearchChange={handleSearch}
        open={false}
        className={styles.search}
      />
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>ID</Table.HeaderCell>
            <Table.HeaderCell>名稱</Table.HeaderCell>
            <Table.HeaderCell>剩餘金額</Table.HeaderCell>
            <Table.HeaderCell>大頭貼</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {users
            .filter(
              (user) =>
                !search ||
                user.name.toLowerCase().includes(search.toLowerCase())
            )
            .map((user) => {
              return (
                <Table.Row key={user.id}>
                  <Table.Cell>{user.id}</Table.Cell>
                  <Table.Cell>{user.name}</Table.Cell>
                  <Table.Cell>NT ${user.balance}</Table.Cell>
                  <Table.Cell>
                    <Image
                      src={user.picture || icon}
                      size="tiny"
                      circular
                    ></Image>
                  </Table.Cell>
                  <Table.Cell>
                    <Button
                      onClick={() => {
                        setModalContent({
                          ...user,
                          title: "儲值設定",
                        });
                        setModalForm(defaultModalForm);
                        setOpen(true);
                      }}
                    >
                      儲值
                    </Button>
                    <Button
                      onClick={() => {
                        setModalContent({
                          ...user,
                          title: "消費設定",
                        });
                        setModalForm(defaultModalForm);
                        setOpen(true);
                      }}
                    >
                      消費
                    </Button>
                    <Button
                      onClick={() => {
                        navigate(`/users/${user.id}`);
                      }}
                    >
                      儲值紀錄
                    </Button>
                  </Table.Cell>
                </Table.Row>
              );
            })}
        </Table.Body>
      </Table>
      <BalanceUpdateModal
        onClose={handleClose}
        open={open}
        modalContent={modalContent}
        error={error}
        modalForm={modalForm}
        onChange={handleChange}
        onSubmit={handleSubmit}
        load={load}
        submitFail={submitFail}
        defaultDateTime={defaultModalForm.order_date}
        showDescription={showDescription}
      />
    </div>
  );
}

export default UserList;
