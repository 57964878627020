import { useEffect, useContext, Suspense } from "react";
import {
  useNavigate,
  useLoaderData,
  useAsyncValue,
  Await,
} from "react-router-dom";
import Loading from "../component/Loading";
import { PermissionContext } from "./PermissionProvider";
import User from "../models/userModel";

function UserDetail() {
  const user = useLoaderData() as User;
  const navigate = useNavigate();
  const permission = useContext(PermissionContext);

  useEffect(() => {
    permission.setAdmin(user.admin);
    permission.setLogin(true);
    navigate(`/users/${user.id}`);
  }, [user]);
  return (
    <Suspense fallback={<Loading />}>
      <Await resolve={user}>(){}</Await>
    </Suspense>
  );
}

export default UserDetail;
