export const getJwtTokenFromCookie = () => {
  const cookies = document.cookie.split(";");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith("jwt_token=")) {
      const [, token] = cookie.split("=");
      return token;
    }
  }
  return null;
};

export const setJwtTokenToCookie = (token) => {
  document.cookie = `jwt_token=${token}; path=/; expires=${new Date(
    Date.now() + 86400e3
  ).toUTCString()}; SameSite=Lax;`;
};
