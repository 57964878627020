import { Image, Grid } from "semantic-ui-react";
import logo from "../assets/logo.png";
import styles from "./loading.module.css";

function Loading() {
  return (
    <Grid textAlign="center" className={styles.grid} verticalAlign="middle">
      <Grid.Column>
        <div className={styles.loader}>
          <Image src={logo} className={styles.img} />
        </div>
      </Grid.Column>
    </Grid>
  );
}

export default Loading;
