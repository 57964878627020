import { Grid, Segment, Image, Header } from "semantic-ui-react";
import icon from "../assets/sign_up_with_google.png";

const CLIENT_ID =
  "903513302027-ekp350r3iorv14dm07ae4v12n472nap7.apps.googleusercontent.com";
const SCOPES = "https://www.googleapis.com/auth/calendar";

function handleAuthClick() {
  const oauth2Endpoint = "https://accounts.google.com/o/oauth2/v2/auth";

  // Create <form> element to submit parameters to OAuth 2.0 endpoint.
  const form = document.createElement("form");
  form.setAttribute("method", "GET"); // Send as a GET request.
  form.setAttribute("action", oauth2Endpoint);

  // Parameters to pass to OAuth 2.0 endpoint.
  const params = {
    client_id: CLIENT_ID,
    // 'redirect_uri': 'http://localhost:3000/oauth/google-calendar/callback',
    redirect_uri:
      "https://www.cheersstudio.info/oauth/google-calendar/callback",
    response_type: "code",
    scope: SCOPES,
    access_type: "offline",
    include_granted_scopes: "true",
    state: "pass-through value",
  };

  // Add form parameters as hidden input values.
  for (const p in params) {
    const input = document.createElement("input");
    input.setAttribute("type", "hidden");
    input.setAttribute("name", p);
    input.setAttribute("value", params[p]);
    form.appendChild(input);
  }

  // Add form to page and submit it to open the OAuth 2.0 endpoint.
  document.body.appendChild(form);
  form.submit();
}

const GoogleCalendarOAuth = () => {
  return (
    <Grid.Row>
      <Grid.Column>
        <Segment>
          <Grid.Column>
            <Header icon>
              <label>預約資訊連結 Google Calendar</label>
            </Header>
            <Image onClick={handleAuthClick} src={icon} size="small" />
          </Grid.Column>
        </Segment>
      </Grid.Column>
    </Grid.Row>
  );
};

export default GoogleCalendarOAuth;
