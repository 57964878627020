import "./normalize.css";
import "semantic-ui-css/semantic.min.css";
import "./index.css";
import { createRoot } from "react-dom/client";
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";

import UserDetail from "./pages/UserDetail";
import { getUser, getUserList } from "./loader/userLoader";
import { getBookingList } from "./loader/bookingLoader";
import NotFound from "./pages/NotFound";
import Liff from "./pages/Liff";
import { initLiff } from "./loader/lineLiffLoader";
import { getUserByCode } from "./loader/lineCallbackLoader";
import { oauthLoader } from "./loader/oauthLoader";
import Login from "./pages/Login";
import Redirect from "./pages/Redirect";
import UserList from "./pages/UserList";
import BookingList from "./pages/BookingList";
import OAuthCallback from "./pages/OAuthCallback";
import AdminDetail from "./pages/AdminDetail";
import PermissionProvider from "./pages/PermissionProvider";
import ProtectedRoute from "./pages/ProtectedRoute";
import PrivacyPolicy from "./pages/PrivacyPolicy";

const RoutesJSX = (
  <>
    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
    <Route
      path="/line/callback"
      element={<Redirect />}
      loader={async ({ request }) => {
        const url = new URL(request.url);
        const code = url.searchParams.get("code");
        return getUserByCode(code as string);
      }}
    />
    <Route
      path="/liff"
      element={<Liff />}
      loader={async () => {
        return initLiff();
      }}
    />
    <Route element={<ProtectedRoute />}>
      <Route path="/" element={<Login />} />
      <Route path="/login" element={<Login />} />
      <Route
        path="/users/:id"
        element={<UserDetail />}
        loader={async ({ params }) => {
          return getUser(params.id as string);
        }}
        errorElement={<NotFound />}
      />
    </Route>
    <Route element={<ProtectedRoute isAdminRoute />}>
      <Route
        path="/users"
        element={<UserList />}
        loader={async () => {
          return getUserList();
        }}
        errorElement={<NotFound />}
      />
      <Route
        path="/bookings"
        element={<BookingList />}
        loader={async () => {
          return getBookingList(new Date());
        }}
        errorElement={<NotFound />}
      />
      <Route
        path="/oauth/google-calendar/callback"
        element={<OAuthCallback />}
        loader={oauthLoader}
      />
      <Route path="/admin" element={<AdminDetail />} />
    </Route>
    <Route path="*" element={<NotFound />} />
  </>
);
const routes = createRoutesFromElements(RoutesJSX);

const router = createBrowserRouter(routes);
createRoot(document.getElementById("root") as HTMLElement).render(
  <PermissionProvider>
    <RouterProvider router={router}></RouterProvider>
  </PermissionProvider>
);
