import liff from "@line/liff";
import { json } from "react-router-dom";

import { getUserByIdToken } from "../api/userAPI";
import User from "../models/userModel";

async function initLiff() {
  await liff.init({ liffId: process.env.REACT_APP_LIFF_ID as string });
  if (!liff.isLoggedIn()) {
    return new Response("No Auth", { status: 401 });
  }

  const idToken = liff.getIDToken();
  try {
    const userInfo: User = await getUserByIdToken(idToken as string);
    if (userInfo.id === undefined) {
      throw new Response("Unknown id", { status: 500 });
    }
    return json(userInfo, { status: 200 });
  } catch (error) {
    console.log(error);
    throw new Response("Unknown error", { status: 500 });
  }
}

export { initLiff };
