import { getBookingList as getBookingListFromServer } from "../api/bookingAPI";
import { json } from "react-router-dom";
import { format, startOfMonth, endOfMonth } from "date-fns";

async function getBookingList(date: Date) {
  try {
    const start: string = format(startOfMonth(date), "yyyy-MM-dd");
    const end: string = format(endOfMonth(date), "yyyy-MM-dd");
    const bookingList = await getBookingListFromServer(start, end);
    return json(bookingList, { status: 200 });
  } catch (e) {
    throw new Response("Not Found", { status: 404 });
  }
}

export { getBookingList };
