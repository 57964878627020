import { useEffect } from "react";
import { useNavigate, useLoaderData } from "react-router-dom";
import Loading from "../component/Loading";

function OAuthCallback() {
  const navigate = useNavigate();
  const data = useLoaderData();

  useEffect(() => {
    navigate("/");
  }, [data, navigate]);

  return <Loading />;
}

export default OAuthCallback;
