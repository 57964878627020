import { useContext, useEffect } from "react";
import liff from "@line/liff";
import User from "../models/userModel";
import { useNavigate, useLoaderData } from "react-router-dom";
import Loading from "../component/Loading";
import { PermissionContext } from "./PermissionProvider";

function Liff() {
  const user = useLoaderData() as User;
  const navigate = useNavigate();
  const permission = useContext(PermissionContext);

  useEffect(() => {
    permission.setAdmin(user.admin);
    setTimeout(() => {
      navigate(`/users/${user.id}`);
    }, 3 * 1000);
  }, [user]);

  if (user.id === undefined) {
    const redirectUri = process.env.REACT_APP_UI_BASE_URL + "/liff";
    setTimeout(() => liff.login({ redirectUri }), 1000);
    return <Loading />;
  }

  return <Loading />;
}

export default Liff;
