import * as userAPI from "../api/userAPI";
import User from "../models/userModel";
import { json } from "react-router-dom";

async function getUserByCode(code: string) {
  try {
    if (code === undefined) {
      return new Response("login fail", { status: 400 });
    }
    const userInfo: User = await userAPI.getUserByCode(code);
    if (userInfo.id === undefined) {
      return new Response("login fail", { status: 400 });
    }
    return json(userInfo, { status: 200 });
  } catch (error) {
    console.log(error);
    return new Response("login fail", { status: 400 });
  }
}

export { getUserByCode };
