import { Grid } from "semantic-ui-react";
import GoogleCalendarOAuth from "../component/GoogleCalendarOAuth";
import styles from "./adminDetail.module.css";

function AdminDetail() {
  return (
    <Grid className={styles.container}>
      <GoogleCalendarOAuth />
    </Grid>
  );
}

export default AdminDetail;
