import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import {
  getJwtTokenFromCookie,
  setJwtTokenToCookie,
} from "../utils/tokenUtils";

interface ServerConfig {
  baseURL: string;
}

class Server {
  private instance: AxiosInstance;

  constructor(private config: ServerConfig) {
    const instance = axios.create(config);
    instance.interceptors.response.use(
      (response: AxiosResponse<any>) => {
        const token = response.headers["authorization"];
        if (token) {
          // 將 token 存儲在 cookie 中
          setJwtTokenToCookie(token);
        }
        return response;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    this.instance = instance;
  }

  public get<T = any>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    const jwtToken = getJwtTokenFromCookie();
    const headers = jwtToken ? { Authorization: `Bearer ${jwtToken}` } : {};
    return this.instance.get<T>(url, { ...config, headers });
  }

  public post<T = any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    const jwtToken = getJwtTokenFromCookie();
    const headers = jwtToken ? { Authorization: `Bearer ${jwtToken}` } : {};
    return this.instance.post<T>(url, data, { ...config, headers });
  }

  public put<T = any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    const jwtToken = getJwtTokenFromCookie();
    const headers = jwtToken ? { Authorization: `Bearer ${jwtToken}` } : {};
    return this.instance.put<T>(url, data, { ...config, headers });
  }

  public delete<T = any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    const jwtToken = getJwtTokenFromCookie();
    const headers = jwtToken ? { Authorization: `Bearer ${jwtToken}` } : {};
    return this.instance.delete<T>(url, { ...config, headers });
  }
}

// 從 process.env 獲取配置資訊
const config: ServerConfig = {
  baseURL: process.env.REACT_APP_API_BASE_URL!,
};

const server = new Server(config);

export default server;
