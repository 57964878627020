import * as React from "react";
import { getJwtTokenFromCookie } from "../utils/tokenUtils";
import { getMe } from "../api/userAPI";

export interface PermissionContextType {
  admin: boolean;
  setAdmin: (admin: boolean) => void;
  isLogin: boolean;
  setLogin: (isLogin: boolean) => void;
  setUserId: (userId: string | undefined) => void;
  userId: string | undefined;
  checkPermission: () => Promise<void>;
}

export const PermissionContext = React.createContext<PermissionContextType>(
  null!
);

export const usePermission = (): PermissionContextType =>
  React.useContext(PermissionContext);

function PermissionProvider({ children }: { children: React.ReactNode }) {
  const [admin, setAdmin] = React.useState<boolean>(false);
  const [isLogin, setLogin] = React.useState<boolean>(false);
  const [userId, setUserId] = React.useState<string | undefined>(undefined);

  const checkPermission = async () => {
    if (getJwtTokenFromCookie() == null) {
      return;
    }
    try {
      const user = await getMe();
      setAdmin(user.admin);
      setUserId(user.id);
      setLogin(true);
    } catch (error) {
      console.error(error);
    }
  };

  const value = {
    admin,
    setAdmin,
    isLogin,
    setLogin,
    userId,
    setUserId,
    checkPermission,
  };

  return (
    <PermissionContext.Provider value={value}>
      {children}
    </PermissionContext.Provider>
  );
}

export default PermissionProvider;
