import lineLoginIcon from "../assets/btn_login_base.png";
import loginJPG from "../assets/login.jpg";
import logo from "../assets/logo.png";
import styles from "./login.module.css";
import {
  Image,
  Grid,
  GridColumn,
  GridRow,
  Header,
  HeaderContent,
  HeaderSubheader,
} from "semantic-ui-react";
import { Navigate } from "react-router-dom";
import { usePermission } from "./PermissionProvider";
const callbackUrl = `${process.env.REACT_APP_UI_BASE_URL}line/callback`;
function Login() {
  const permission = usePermission();
  if (permission.isLogin) {
    return <Navigate to={`/users/${permission.userId}`} />;
  }
  return (
    <Grid className={styles.container}>
      <GridColumn width={9} style={{ padding: "0" }}>
        <Image src={loginJPG} style={{ borderRadius: "10px" }} />
      </GridColumn>
      <GridColumn
        width={7}
        textAlign="center"
        verticalAlign="middle"
        className={styles.loginContainer}
      >
        <GridRow className={styles.header}>
          <Header size="small">
            <Image src={logo} />
            <HeaderContent>
              Cheers Studio
              <HeaderSubheader>Manage your ears</HeaderSubheader>
            </HeaderContent>
          </Header>
        </GridRow>
        <GridRow className={styles.line}>
          <a
            href={`https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${
              process.env.REACT_APP_LINE_CHANNEL_ID
            }&redirect_uri=${encodeURIComponent(
              callbackUrl
            )}&state=12345abcde&scope=profile%20openid&nonce=09876xyz`}
          >
            <Image
              src={lineLoginIcon}
              size="small"
              style={{ margin: "auto" }}
            />
          </a>
        </GridRow>
      </GridColumn>
    </Grid>
  );
}

export default Login;
