import server from "./server";
import User from "../models/userModel";

async function getUser(): Promise<User[]> {
  return await server
    .get(`/api/v1/users`)
    .then((response: any) => response.data);
}

async function getUserById(id: string): Promise<User> {
  return await server
    .get(`/api/v1/users/${id}`)
    .then((response: any) => response.data);
}

async function getUserByCode(code: string): Promise<User> {
  return await server
    .post("/api/v1/users/register", {
      code,
      login_type: "LINE",
    })
    .then((response: any) => {
      return response.data;
    });
}

async function getUserByIdToken(idToken: string): Promise<User> {
  return await server
    .post("/api/v1/users/register", {
      id_token: idToken,
      login_type: "LIFF",
    })
    .then((response: any) => response.data);
}

async function getMe(): Promise<User> {
  return await server
    .get("/api/v1/users/me")
    .then((response: any) => response.data);
}

async function getBalanceHistory(userId: string): Promise<User> {
  return await server
    .get(`/api/v1/users/${userId}/balance/history`)
    .then((response: any) => response.data);
}

async function deleteBalanceHistory(
  userId: string,
  transactionId: string
): Promise<void> {
  await server.delete(
    `/api/v1/users/${userId}/balance?transaction_id=${transactionId}`
  );
}

async function updateBalance(
  userId: string | undefined,
  type,
  transactionData
): Promise<User> {
  return await server
    .post(`/api/v1/users/${userId}/${type}`, [transactionData])
    .then((response: any) => response.data);
}

export {
  getUserByCode,
  getUserByIdToken,
  getUserById,
  getBalanceHistory,
  getUser,
  getMe,
  updateBalance,
  deleteBalanceHistory,
};
