import server from "./server";

async function oauth2googleCalendar(code: string): Promise<void> {
  return await server
    .get(`/api/v1/oauth/google-calendar?code=${code}`)
    .then((response: any) => response.data);
}

export {
    oauth2googleCalendar
};
