import { Container, Header, Segment } from "semantic-ui-react";

const PrivacyPolicy = () => {
  return (
    <Container>
      <Header as="h1" textAlign="center" style={{ margin: "20px 0" }}>
        隱私政策
      </Header>
      <Segment>
        <Header as="h2">收集的信息</Header>
        <p>我們通過以下方式收集信息：</p>
        <ul>
          <li>
            <strong>您提供給我們的信息</strong>
            ：當您進行預約時，我們會要求您提供個人信息，如姓名、電子郵件地址等，以便我們在Google
            Calendar中為您保存預約記錄。
          </li>
          <li>
            <strong>我們從第三方獲得的信息</strong>
            ：為了提供預約功能，我們會使用Google Calendar API來存取您的Google
            Calendar數據。我們僅在獲得您明確授權時才存取您的日曆信息。
          </li>
        </ul>
        <Header as="h2">使用的信息</Header>
        <p>我們使用收集的信息來：</p>
        <ul>
          <li>在Google Calendar中為您創建和管理預約。</li>
          <li>提供、維護、改進我們的服務，包括客戶支持。</li>
          <li>通過電子郵件或其他方式向您發送與您預約相關的通知和更新。</li>
        </ul>
        <Header as="h2">分享的信息</Header>
        <p>我們不會與任何第三方分享您的個人信息，除非：</p>
        <ul>
          <li>獲得您的同意。</li>
          <li>出於法律要求或應法律程序的要求。</li>
          <li>
            保護 Cheers studio、我們的用戶或公眾的權利、財產或安全需要時。
          </li>
        </ul>
        <Header as="h2">數據安全</Header>
        <p>
          我們採用商業上合理的物理、技術和管理措施來保護您的個人信息不被未經授權的訪問和使用。
        </p>
        <Header as="h2">隱私政策的更改</Header>
        <p>
          我們可能會不時更新我們的隱私政策。我們將通過在本頁面上發布新的隱私政策來通知您任何更改。
        </p>
        <Header as="h2">聯絡我們</Header>
        <p>
          如果您對我們的隱私政策有任何疑問或擔憂，請通過
          <a href="mailto:u9653022@gmail.com">E-mail</a>聯絡我們。
        </p>
      </Segment>
    </Container>
  );
};

export default PrivacyPolicy;
