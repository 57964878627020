import { Form, Button, Modal, Image, Grid, Message } from "semantic-ui-react";
import _ from "lodash";
import icon from "../assets/user.png";
import { BaseSyntheticEvent } from "react";

const createDefaultDateTime = () => {
  const now = new Date();
  now.setMinutes(0 - now.getTimezoneOffset());
  now.setMilliseconds(0);
  now.setSeconds(0);

  return now.toISOString().slice(0, -1);
};

export const defaultModalForm = {
  amount: "100",
  description: "",
  order_date: createDefaultDateTime(),
};

export type ModalForm = {
  amount?: string;
  description?: string;
  order_date?: string;
};

export type ModalContent = {
  title: string;
  picture?: string;
  name: string;
  id?: string;
};

interface BalanceUpdateProps {
  open: boolean;
  onClose: () => void;
  modalContent: ModalContent;
  error: ModalForm;
  modalForm: ModalForm;
  onChange: (e: BaseSyntheticEvent) => void;
  onSubmit: () => void;
  load: boolean;
  submitFail: boolean;
  defaultDateTime: string;
  showDescription: boolean;
}

function BalanceUpdateModal(props: BalanceUpdateProps) {
  const noError = Object.keys(props.error).every((key) =>
    _.isEmpty(props.error[key])
  );
  return (
    <Modal onClose={props.onClose} open={props.open} closeOnDimmerClick={false}>
      <Modal.Header>{props.modalContent.title}</Modal.Header>
      <Modal.Content image>
        <Message
          hidden={!props.submitFail}
          error
          header="Submit Fail"
          content="Please try again after 1 min"
        />
        <Grid columns="equal" divided inverted padded>
          <Grid.Row centered>
            <Image
              src={props.modalContent.picture || icon}
              size="tiny"
              circular
            ></Image>
          </Grid.Row>
          <Grid.Row centered>
            <label>{props.modalContent.name}</label>
          </Grid.Row>
        </Grid>
        <Modal.Description>
          <Form>
            <Form.Input
              placeholder="100"
              type="number"
              name="amount"
              label="金額"
              defaultValue={props.modalForm.amount}
              onChange={props.onChange}
              error={props.error.amount}
            />
            {props.showDescription && (
              <Form.Input
                placeholder="微醺一下"
                label="描述"
                type="text"
                name="description"
                onChange={props.onChange}
                error={props.error.description}
              />
            )}

            <Form.Input
              placeholder="2023-01-01T00:00"
              type="datetime-local"
              label="時間"
              defaultValue={props.defaultDateTime}
              onChange={props.onChange}
              error={props.error.order_date}
            />
          </Form>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={props.onClose}>Cancel</Button>
        <Button
          loading={props.load}
          content="Submit"
          disabled={!noError || props.load}
          onClick={props.onSubmit}
          positive
        />
      </Modal.Actions>
    </Modal>
  );
}

export default BalanceUpdateModal;
