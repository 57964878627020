import { getUserById, getUser as getUserFromServer } from "../api/userAPI";

import { json } from "react-router-dom";

async function getUser(id: string) {
  try {
    const user = await getUserById(id);
    // const user = { id: 1, balance: 100 };
    return json(user, { status: 200 });
  } catch (e) {
    throw new Response("Not Found", { status: 404 });
  }
}

async function getUserList() {
  try {
    const user = await getUserFromServer();
    // const user = { id: 1, balance: 100 };
    return json(user, { status: 200 });
  } catch (e) {
    throw new Response("Not Found", { status: 404 });
  }
}

export { getUser, getUserList };
