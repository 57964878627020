import { ReactElement } from "react";
import { Button, Modal } from "semantic-ui-react";
interface DeleteModalProps {
  open: boolean;
  content: ReactElement;
  onClose: () => void;
  onSubmit: () => Promise<void>;
}
const DeleteModal = ({
  open,
  content,
  onClose: handleClose,
  onSubmit: handleSubmit,
}: DeleteModalProps) => {
  return (
    <Modal open={open}>
      <Modal.Header>刪除</Modal.Header>
      <Modal.Content>{content}</Modal.Content>
      <Modal.Actions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSubmit} negative>
          Delete
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default DeleteModal;
